import { environment } from '../../../../../environments/environment';

export abstract class UrlBuilder {
  private urlEnvironment: string;

  constructor(path: string) {
    this.urlEnvironment = `${environment.rpp.apiUrl}/${path}`;
  }

  protected getApiUrl() {
    return this.urlEnvironment;
  }
}
