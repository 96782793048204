import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '../localstorage.service';
import { LoginResponse } from '../../interfaces/auth.interface';
import { Router } from '@angular/router';
import { UrlBuilder } from './config/url-builder';
import { AuthDto, AuthResponseDto } from '@/domain/auth/dtos/auth.dto';
import LocalStorageKey from '@/shared/utils/constants/local-storage-key.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends UrlBuilder {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    super('auth');
  }

  get currentToken(): AuthDto {
    return this.localStorageService.getLocalStorageItem(LocalStorageKey.USER);
  }

  login(login: { username: string; password: string }) {
    const queryUrl = `${this.getApiUrl()}/login`;
    return this.http.post<AuthResponseDto>(queryUrl, login);
  }

  logout() {
    this.localStorageService.removeLocalStorageData();
    this.router.navigate(['/auth/login']);
  }

  validateToken(): Observable<LoginResponse> {
    const queryUrl = `${this.getApiUrl()}/validate`;
    return this.http.post<LoginResponse>(queryUrl, {});
  }
}
